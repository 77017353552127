interface RefOptionInterface {
  title: string
  isActive?: boolean
  onClickOption?: () => void
}

const RefOption = (props: RefOptionInterface) => {
  const { title = '', isActive = false, onClickOption = () => {} } = props
  return (
    <div
      className={`${
        isActive
          ? `bg-secondary text-white`
          : `bg-secondary/[.21] text-primary/[.7]`
      }  
        mr-5 py-1 px-5 rounded-lg font-medium cursor-pointer text-sm capitalize`}
      onClick={onClickOption}
    >
      {title}
    </div>
  )
}

export default RefOption
