import { Modal } from 'antd'
import { useState } from 'react'

interface RefPhotoInterface {
  path?: string
}

const RefPhoto = (props: RefPhotoInterface) => {
  const baseURL = process.env.REACT_APP_API_ENDPOINT
  const [previewPhoto, setPreviewPhoto] = useState<boolean>(false)
  const onPreview = () => {
    setPreviewPhoto(true)
  }
  return (
    <>
      <Modal
        open={previewPhoto}
        footer={null}
        onCancel={() => setPreviewPhoto(false)}
        closable={false}
        centered
        width={'60vw'}
        className="bg-center bg-contain bg-no-repeat modal-preview"
        style={{
          height: '90vh',
          backgroundImage: `url(${baseURL}/files/common/show/reference/${props.path})`,
        }}
        data-testid="modal-preview"
      ></Modal>
      {props.path ? (
        <img
          src={`${baseURL}/files/common/show/reference/${props.path}`}
          alt="refPhoto"
          onClick={onPreview}
          className="cursor-pointer"
          height={80}
          data-testid="img-ref-photo"
        />
      ) : (
        <>-</>
      )}
    </>
  )
}

export default RefPhoto
