interface RefInfoInterface {
  famille?: string
  sous_famille?: string
  marque?: string
}

const RefInfo = (props: RefInfoInterface) => {
  const { famille = '', sous_famille = '', marque = '' } = props
  return (
    <>
      {!famille && !sous_famille && !marque ? (
        <ol className="list-none p-0 m-0">
          <li></li>
          <li>-</li>
          <li></li>
        </ol>
      ) : (
        <ol className="list-none p-0 m-0">
          <li>{marque}</li>
          <li>{famille}</li>
          <li>{sous_famille}</li>
        </ol>
      )}
    </>
  )
}

export default RefInfo
