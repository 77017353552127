import { Col, Form, Input, Row, Select } from 'antd'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../app/store'
import CLOSE_ICON from '../../../assets/icons/close.svg'
import DUPLICATE_ICON from '../../../assets/icons/duplicate.svg'
import SEARCH_ICON from '../../../assets/icons/search.svg'
import CustomDropdown from '../../../components/Common/CustomDropdown/CustomDropdown'
import { ActionOnReference } from '../../../enum/ActionOnReference'
import { ReferenceDataType } from '../../../models'
import { getAllReference } from '../../../redux/reducers/referenceSlice'
import { makeTableValue } from '../../../utils/reference/makeTableValue'
import { handleOptions } from '../func'
import RefOption from './RefOptionItem'
import './Reference.scss'
import '../client/Client.scss'
import RefCatalogue from './catalogue/RefCatalogue'
import NewActionRow from '../../stock/components/ActionRow/NewActionRow'
import Define from '../../../constants/define'

interface OptionInterface {
  title: string
  isActive: boolean
}

enum OptionsTitle {
  CATALOGUE = 'Catalogue',
  CATEGORIES = 'Catégories',
  CONDITIONEMENTS = 'Conditionnements',
  ICONE = 'Icônes',
}

const options: OptionInterface[] = [
  {
    title: OptionsTitle.CATALOGUE,
    isActive: true,
  },
  {
    title: OptionsTitle.CATEGORIES,
    isActive: false,
  },
  {
    title: OptionsTitle.CONDITIONEMENTS,
    isActive: false,
  },
  {
    title: OptionsTitle.ICONE,
    isActive: false,
  },
]

const singleActions = ['duplicate']

const ReferenceScreen = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const count = searchParams.get('count')
  const [currentOpt, setCurrentOpt] = useState<OptionInterface>(options[0])
  const [refData, setRefData] = useState<ReferenceDataType[]>([])
  const [displayAllRef, setDisplayAllRef] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { currentReferenceList } = useSelector(
    (state: RootState) => state.reference
  )
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [listOption, setListOption] = useState<any[]>([
    {
      disabled: true,
      value: 'duplicate',
      label: (
        <Row gutter={8} align={'middle'}>
          <Col>
            <img
              src={DUPLICATE_ICON}
              width={15}
              alt=""
              style={{ marginTop: 5 }}
            />
          </Col>
          <Col>Dupliquer</Col>
        </Row>
      ),
    },
    {
      disabled: true,
      value: 'duplicate',
      label: (
        <Row gutter={8} align={'middle'}>
          <Col>
            <img
              src={DUPLICATE_ICON}
              width={15}
              alt=""
              style={{ marginTop: 5 }}
            />
          </Col>
          <Col>Editer</Col>
        </Row>
      ),
    },
  ])

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    if (!(currentOpt.title === OptionsTitle.CATALOGUE)) {
      return
    }
    setDisplayAllRef(true)

    dispatch(
      getAllReference({
        limit: Number(pageSize) || 25,
        offset: (Number(pageIndex) - 1) * Number(pageSize) || 0,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, count])

  const onClickOption = (option: OptionInterface) => {
    setDisplayAllRef(true)
    setCurrentOpt(option)
    if (option.title === OptionsTitle.CATALOGUE) {
      dispatch(
        getAllReference({
          limit: Number(pageSize) || 25,
          offset: (Number(pageIndex) - 1) * Number(pageSize) || 0,
        })
      )
    } else {
      setRefData([])
      setDisplayAllRef(false)
    }
  }

  useEffect(() => {
    const refList = makeTableValue(currentReferenceList.referenceList)
    setRefData(refList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReferenceList, pageIndex, pageSize])

  const onChangeSelected = (selectedRowKeys: string[]) => {
    let listOptionClone = cloneDeep(listOption)

    listOptionClone = handleOptions(
      selectedRowKeys,
      listOptionClone,
      singleActions
    )

    setListOption(listOptionClone)
    setSelectedIds(selectedRowKeys)
    setSelectedOption(undefined)
  }

  const rowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? 'table-row-main-even' : 'table-row-main-odd'
  }

  const onChangeOption = (value: string) => {
    setSelectedOption(value)

    switch (value) {
      case ActionOnReference.duplicate: {
        const newPath = location.pathname.split('/')
        newPath.push(selectedIds[0])
        navigate(
          `${newPath.join(
            '/'
          )}?action=duplicate&page-index=${pageIndex}&page-size=${pageSize}`
        )
        break
      }
      default:
        return
    }
  }

  useEffect(() => {
    let listOptionClone = cloneDeep(listOption)
    listOptionClone = handleOptions(selectedIds, listOptionClone, singleActions)

    setListOption(listOptionClone)
    setSelectedOption(undefined)
  }, [location.pathname])

  const openRefCreatePopup = () => {
    navigate(
      `/configurations/reference/ref-create?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  return (
    <>
      <div className="relative text-xs">
        <div
          className="research-bar bg-[#EEEEEE] mx-5 px-5 py-2 flex flex-row justify-between items-center"
          style={{
            borderBottomRightRadius: 7,
            borderBottomLeftRadius: 7,
          }}
        >
          <div className="grid grid-cols-7 gap-x-4 w-full">
            <Form.Item noStyle label="" name="" colon={false}>
              <Input placeholder="Référence" className="research-bar-input" />
            </Form.Item>
            <Form.Item noStyle label="" name="" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Tous Clients"
              />
            </Form.Item>
            <div className="flex flex-row gap-x-2">
              <img src={SEARCH_ICON} alt="masques" />
              <img src={CLOSE_ICON} alt="masques" />
            </div>
          </div>
        </div>
      </div>
      <div className="table-wrapper" style={{ margin: '0 auto' }}>
        <div className="my-1 page-title-heading-1 flex items-center justify-between">
          <div className="flex">
            Références
            <div className="flex ml-5">
              {options.map((item, index) => (
                <RefOption
                  key={index}
                  title={item.title}
                  onClickOption={() => onClickOption(item)}
                  isActive={item.title === currentOpt.title}
                />
              ))}
            </div>
          </div>
          <div className="flex items-center">
            {selectedRowKeys.length >= 1 && (
              <Select
                value={selectedOption}
                style={{ width: 165, marginRight: -40 }}
                options={listOption}
                onChange={onChangeOption}
                placeholder={<span className="italic capitalize">Actions</span>}
              />
            )}
            <NewActionRow
              isClickUp
              onClickPlus={openRefCreatePopup}
              isHideExport
              isDisableAdd={
                !localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM)
              }
            />
          </div>
        </div>
      </div>

      {displayAllRef && (
        <RefCatalogue
          data={refData}
          loading={currentReferenceList.loading}
          metaData={currentReferenceList.metadata}
          onChangeSelected={onChangeSelected}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          rowClassName={rowClassName}
        />
      )}
    </>
  )
}

export default ReferenceScreen
