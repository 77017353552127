export const handleOptions = (
  selectedRowKeys: string[],
  listOption: any[],
  singleActions: string[]
) => {
  if (selectedRowKeys.length === 0)
    listOption = listOption.map((item) => {
      item.disabled = true
      return item
    })
  else if (selectedRowKeys.length === 1) {
    listOption = listOption.map((item) => {
      item.disabled = false
      return item
    })
  } else {
    listOption = listOption.map((item) => {
      if (singleActions.includes(item.value)) item.disabled = true
      else item.disabled = false
      return item
    })
  }
  return listOption
}
