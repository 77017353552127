interface RefGestionInterface {
  dluo?: boolean
  dlc?: boolean
  dlv?: boolean
  lot?: boolean
}

const RefGestion = (props: RefGestionInterface) => {
  const { dluo, dlc, dlv, lot } = props

  return (
    <>
      {!dluo && !dlc && !dlv && !lot ? (
        <p>-</p>
      ) : (
        <ol className="list-none p-0 m-0">
          <li>{dluo && 'DLUO'}</li>
          <li>{dlv && 'DLV'}</li>
          <li>{dlc && 'DLC'}</li>
          <li>{lot && 'LOT'}</li>
        </ol>
      )}
    </>
  )
}

export default RefGestion
