import { Pagination, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useState } from 'react'
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import SelectRender from '../../../../components/Common/DataType/SelectType'
import { PagingEnum } from '../../../../enum'
import useResizeScreen from '../../../../hook/useResizeScreen'
import { ReferenceDataType } from '../../../../models'
import { Metadata } from '../../../../models/common/common'
import { renderDigitsByNumber } from '../../../../utils'
import '../Reference.scss'
import RefGestion from './RefGestion'
import RefInfo from './RefInfo'
import RefMultiItem from './RefMultiItem'
import RefPhoto from './RefPhoto'

interface RefCatalogueInterface {
  data: ReferenceDataType[]
  loading?: boolean
  metaData?: Metadata
  onChangeSelected?: (selectedRowKeys: string[]) => void
  selectedRowKeys?: string[]
  setSelectedRowKeys?: any
  rowClassName: any
}

const RefCatalogue = (props: RefCatalogueInterface) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isShowLess, setIsShowLess] = useState<boolean>(false)
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')

  const { selectedRowKeys = [], setSelectedRowKeys } = props

  let height = useResizeScreen({
    pixels: 14,
  })

  const columns: ColumnsType<ReferenceDataType> = [
    {
      title: <div className="text-center">Select</div>,
      dataIndex: 'select',
      key: 'select',
      width: 100,
      fixed: 'left',
      render: (_, row) => {
        return (
          <SelectRender
            isMainLine={true}
            isCheck={selectedRowKeys.includes(row.id || '')}
            onChangeChecked={(e) => {
              if (e.target.checked) {
                if (props.onChangeSelected)
                  props.onChangeSelected([...selectedRowKeys, row.id || ''])
                setSelectedRowKeys([...selectedRowKeys, row.id || ''])
              } else {
                if (props.onChangeSelected)
                  props.onChangeSelected(
                    [...selectedRowKeys].filter((i) => i !== row.id)
                  )
                setSelectedRowKeys(
                  [...selectedRowKeys].filter((i) => i !== row.id)
                )
              }
            }}
            screenColor="blue"
          />
        )
      },
    },
    {
      title: 'Libellé',
      dataIndex: 'libelle',
      width: 520,
      render: (_, row) => {
        return (
          <div title={row.libelle_long}>
            {renderDigitsByNumber(row.libelle_long, 40)}
          </div>
        )
      },
    },
    {
      title: 'N°',
      dataIndex: 'nom',
      key: 'nom',
      width: 280,
      render: (_, row) => {
        return (
          <Link
            className="hover-text"
            to={`/configurations/reference/${
              row.id
            }?page-index=${pageIndex}&page-size=${pageSize}${
              row.variante ? `&variante=${row.variante}` : ''
            }`}
          >
            {row.nom}
          </Link>
        )
      },
    },
    {
      title: 'Client(s)',
      dataIndex: 'clients',
      key: 'clients',
      width: 320,
      render: (_, row) => {
        return <>{row.client}</>
      },
    },
    {
      title: 'Origine',
      dataIndex: 'origine',
      key: 'origine',
      width: 320,
      render: (_, row) => {
        return <>{row.origine}</>
      },
    },
    {
      title: 'Informations',
      dataIndex: 'informations',
      key: 'informations',
      width: 480,
      render: (_, row) => {
        return (
          <RefInfo
            marque={row.marque}
            famille={row.famille}
            sous_famille={row.sous_famille}
          />
        )
      },
    },
    {
      title: 'Gestion',
      dataIndex: 'gestion',
      key: 'gestion',
      width: 100,
      render: (_, row) => {
        return (
          <RefGestion
            dluo={row.dluo}
            dlc={row.dlc}
            dlv={row.dlv}
            lot={row.lot}
          />
        )
      },
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 100,
      render: (_, row) => {
        return <RefPhoto path={row.photo} />
      },
    },
    {
      title: 'Cdn',
      dataIndex: 'cdn',
      key: 'cdn',
      width: 230,
      render: (_, row) => {
        return <RefMultiItem data={row.conditionnement || []} />
      },
    },
    {
      title: 'Fournisseur(s)',
      dataIndex: 'fournisseur',
      key: 'fournisseur',
      width: 480,
      render: (_, row) => {
        return <RefMultiItem data={row.ref_fournissers || []} />
      },
    },
    {
      title: 'Mise à jour',
      dataIndex: 'updateAt',
      key: 'updateAt',
      width: 200,
      render: (_, row) => {
        return <>{row.update_at}</>
      },
    },
  ]

  return (
    <div className="config-table-style">
      <div className="pl-10">
        <Table
          style={height}
          className="overflow-y-auto reference-table config-table config-table-wrapper relative text-[#EBEBEB]"
          columns={columns}
          pagination={false}
          indentSize={0}
          loading={props.loading}
          dataSource={props.data}
          rowClassName={props.rowClassName}
        />
      </div>

      {props.data && (
        <Pagination
          className="custom-pagination reference-pagination table-wrapper"
          style={{ margin: '20px auto' }}
          locale={{ items_per_page: '' }}
          total={props.metaData?.total || 0}
          defaultPageSize={PagingEnum.DEFAULT_PAGE_SIZE}
          showLessItems={isShowLess}
          pageSizeOptions={[
            PagingEnum.DEFAULT_PAGE_SIZE,
            PagingEnum.PAGE_SIZE_1,
            PagingEnum.PAGE_SIZE_2,
          ]}
          pageSize={Number(pageSize)}
          current={Number(pageIndex)}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0] >= 0 ? range[0] : 0}-${
              range[1] >= 0 ? range[1] : 0
            } sur ${total}`
          }
          onChange={(page, size) => {
            navigate(
              `/configurations/reference?page-index=${page}&page-size=${size}`
            )
            setIsShowLess(() => {
              return page > 4
            })
          }}
        />
      )}
      <Outlet />
    </div>
  )
}

export default RefCatalogue
