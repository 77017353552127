import moment from 'moment'
import { ReferenceDataType, ReferenceRequest } from '../../models'
import { t } from 'i18next'

export const makeTableValue = (refArray: ReferenceRequest[]) => {
  let refList: ReferenceDataType[] = []
  if (refArray) {
    refArray.forEach((e, index) => {
      refList.push(makeRowParentValue(e, index))
    })
  }
  return refList
}
const makeRowParentValue = (ref: ReferenceRequest, index: number) => {
  let createAt = (ref?.create_at || 0) * 1000
  let updateAt = (ref?.update_at || 0) * 1000
  let value: ReferenceDataType = {
    key: index,
    select: true,
    id: ref.id,
    nom: ref.nom,
    client_id: ref.client_id || '',
    client: ref.client_code_nom || '',
    libelle_long: ref.libelle_long,
    photo: ref.photo,
    famille: ref.famille,
    sous_famille: ref.sous_famille,
    origine: ref.origine || '-',
    conditionnement: ref.conditionnement,
    dlc: ref.dlc,
    dlv: ref.dlv,
    dluo: ref.dluo,
    lot: ref.gestion,
    ref_fournissers: ref.ref_fournissers || [],
    marque: ref.marque,
    variante: ref.variante,
    create_at: moment(createAt).format(t('time-format')),
    update_at: moment(updateAt).format(t('time-format')),
  }

  return value
}
