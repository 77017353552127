import { Tooltip } from 'antd'
import { useState } from 'react'
import { Conditionnement, RefFournisser } from '../../../../models'

interface RefMultiItemInterface {
  data: Conditionnement[] | RefFournisser[]
}

const mapType = (data: Conditionnement[] | RefFournisser[]) => {
  let name_nom_or_fnsId = []
  let items
  if (data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if ('nom' in data[i]) {
        items = data[i] as Conditionnement
        name_nom_or_fnsId.push(items.nom || '')
      } else {
        items = data[i] as RefFournisser
        name_nom_or_fnsId.push(items.fournisser_id || '')
      }
    }
  }
  return name_nom_or_fnsId
}

const RefMultiItem = (props: RefMultiItemInterface) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  let res = mapType(props.data)

  return (
    <>
      {props.data.length === 0 ? (
        <p>-</p>
      ) : props.data.length > 1 ? (
        <Tooltip
          title={<RefMultiItemTooltip data={props.data} />}
          color="#FFF"
          overlayInnerStyle={{ borderRadius: 0, color: '#000' }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
        >
          <div
            className="rounded-full text-white bg-secondary text-center"
            style={{ height: 23, width: 23 }}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          >
            <div
              className="flex justify-center items-center font-bold"
              style={{ height: 23, fontSize: 15 }}
            >
              {props.data.length}
            </div>
          </div>
        </Tooltip>
      ) : (
        <>{res}</>
      )}
    </>
  )
}

export default RefMultiItem

const RefMultiItemTooltip = (props: RefMultiItemInterface) => {
  return (
    <div>
      {props.data?.map((item, idx) => {
        return (
          <p key={idx}>
            {/*@ts-ignore*/}
            {item.nom || item.fournisser_id || ''}
            {item.variante ? ` - V ${item.variante}` : ''}
          </p>
        )
      })}
    </div>
  )
}
